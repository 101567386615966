@import "src/assets/styles/variables.scss";

body {
    background-color: $brand-color;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $brand-color-contrast;
    margin: 0;
    padding: 0;
}

* {
    color: $brand-color-contrast;
}