
$brand-color: #4c3a52;
$brand-color-secondary: #000;
$brand-color-accent: #e1c1e7;
$brand-color-contrast: #fff;

$color-border: $brand-color;
$color-error: #d16969;
$color-warning: #cca700;
$color-success: #00a4a4;
$color-neutral: #888;;