@import "src/assets/styles/variables.scss";

.footer-wrapper {
    padding: 10px 0;
    display: flex;
    
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;

    .copyright {
        text-align: center;
        font-size: .75em;
    }
}