@import "src/assets/styles/variables.scss";

.header-banner-wrapper {
    display: flex;
    padding: 20px;

    .header-left-content {
        flex-grow: 1;
    }

    .header-banner-content {
        text-transform: uppercase;
        flex-grow: 2;
    }

    .social-container {
        display: flex;
        flex-grow: 1;
        gap: 20px;
        justify-content: center;

        .icon {
            height: 30px;
            width: 30px;
        }
    }
}