@import "src/assets/styles/variables.scss";

.home-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100vh;

    // border: 1px solid $brand-color-contrast; // uncomment to see bounds of content frame

    .header-container {
    }

    .home-content {
        background-color: lighten($brand-color-accent, 11%);
        border-top: 1px solid $brand-color-secondary;
        border-bottom: 1px solid $brand-color-secondary;
        overflow: scroll;
        overflow: hidden;
        

        .under-dev-img {
            object-fit: fill;
            width: 100%;
            max-width: 1200px;
        }
    }

    .footer-container {

    }
}