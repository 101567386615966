@import "src/assets/styles/variables.scss";

.header-wrapper {

    .header-content {
        padding: 15px 0;
        display: flex;
        flex-direction: row;
        background-color: $brand-color-contrast;
        color: $brand-color-secondary;
    }

    .menu-left,
    .menu-right {
        flex-grow: 1;
        align-content: center;

        ul {
            justify-content: space-between;
            display: flex;
            list-style-type: none;
            padding: 0;
            margin: 0; 
            gap: 20px;
            padding: 0 80px;
        }

        li {
            color: $brand-color-secondary;
            text-transform: uppercase;
        }
    }

    .menu-left {
    }

    .header-logo {
        flex-grow: 0;
        img {
            height: 150px;
        }
    }

    .menu-right {
        
    }
}